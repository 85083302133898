.App{
  display: flex;
  flex-flow: column;
  /* height: 100%; */
  width: 100%;
}
@font-face {
  font-family: 'Cano';
  src: local('Cano'), url(./fonts/Cano.otf) format('opentype');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


body{
  margin: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  background: black;
  color: white;
  font-family: Raleway;
}

button{
  font-size: 11px;
  text-transform: uppercase;
  letter-spacing: .2rem;


}

#app{
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}


canvas{
  position: absolute;
  top: 0;
  z-index: -1;
} */